.title-hover {
	height: 90vh;
	z-index: 200;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: left;
	margin-left: 4%;
}
.title-hover-subtitle {
	font-size: 0.9em;
	color: var(--sec-dark);
	letter-spacing: 3px;
	font-weight: 400;
	word-spacing: 4%;
	padding-left: 60px;
	line-height: 25px;
	margin-top: -6%;
}
.scrolldown {
	color: var(--main-medium);
	text-align: center;
	letter-spacing: 3px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: 1.4em;
	&__icon {
		margin-right: 10px;
	}
	&:hover {
		color: var(--main-darker);
	}
}
.Mouse {
	position: relative;
	--maskX: 0;
	--maskY: 50;

	h1 {
		font-size: 3em;
		letter-spacing: 5px;
		padding: 60px;
		margin: 0;
		font-family: "ShadowsIntoLight", sans-serif;
	}
}
.Mouse-title {
	text-align: left;
	color: var(--main-dark);
	cursor: crosshair;
}
.Mouse-mask {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: var(--main-medium);
	transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
	clip-path: polygon(
		0 0,
		calc(var(--maskX) * 1% + (var(--maskY) - 50) * 0.4%) 0,
		calc(var(--maskX) * 1% + (var(--maskY) - 50) * -0.4%) 100%,
		0 100%
	);
}

@media only screen and (max-width: 332px) {
	.title-hover {
		margin-left: 0%;
	}
	.title-hover-subtitle {
		font-size: 1em;
		color: var(--sec-dark);
		letter-spacing: 1.6px;
		font-weight: 400;
		word-spacing: 3%;
		padding-left: 60px;
		padding-right: 10px;
		margin-top: -6%;
	}
}

@media only screen and (min-width: 732px) {
	.title-hover {
		margin-left: 10%;
	}
	.title-hover-subtitle {
		font-size: 1.3em;
		color: var(--sec-dark);
		letter-spacing: 3px;
		font-weight: 400;
		word-spacing: 4%;
		padding-left: 60px;
		margin-top: -6%;
	}
	.Mouse h1 {
		font-size: 8em;
		letter-spacing: 5px;
		padding: 60px;
	}
}
@media only screen and (min-width: 1130px) {
	.title-hover {
		z-index: 200;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		margin-left: 7%;
	}
	.title-hover-subtitle {
		font-size: 2em;
		color: var(--sec-dark);
		letter-spacing: 3px;
		font-weight: 400;
		word-spacing: 4%;
		padding-left: 60px;
		margin-top: -8%;
	}
	.Mouse h1 {
		font-size: 12em;
		letter-spacing: 5px;
		padding: 60px;
		margin-top: -8%;
	}
}
