.Footer {
	height: auto;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	top: 0;
	left: 0;
	color: white;
	height: 100%;
	&__part1 {
		width: 80%;
		margin-left: 15%;
		&__intouch {
			&__like {
				text-align: center;
				font-size: 1.9em;
				font-weight: 700;
				padding-top: 12%;
			}
			&__talk {
				text-align: center;
				font-weight: 500;
				font-size: 1.1em;
				margin: 10px auto;
			}
			&__button {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: 4%;
				margin-top: 4%;
				&__btn {
					background-color: #f49a9895;
					color: white;
					font-family: "ShadowsIntoLight";
					letter-spacing: 1px;
					font-size: 1em;
					border: solid 2px #dc6969;
					border-radius: 20px;
					padding: 4px 50px;
					box-shadow: 2px 4px 8px #f49a9870;
					transition: 0.3s ease-out;
					margin: 2% auto;
				}
				&__btn:hover {
					cursor: pointer;
					background-color: #f49a9845;
					color: white;
					border: solid 2px #dc6969;
					box-shadow: 1px 1px 2px #f49a9870;
					transition: 0.2s ease-in;
				}
			}
		}
		&__resume {
			border: white 1px solid;
			width: 100%;
			margin-left: 12%;
			margin: 15% auto;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			&__title {
				font-family: "ShadowsIntoLight";
				text-align: center;
				font-size: 1.2em;
				margin-bottom: 20px;
				margin-top: 20px;
				letter-spacing: 2.4px;
			}
			&__link {
				display: flex;
				justify-content: space-evenly;
				align-items: center;
				margin-bottom: 20px;
				letter-spacing: 2px;
				font-size: 0.9em;
				width: 100%;
				&__doc {
					text-decoration: none;
					color: white;
					padding: 2px 4px;
					border-radius: 4px;
				}
				&__doc:hover {
					color: var(--sec-dark);
					cursor: pointer;
					background-color: var(--sec-light);
				}
			}
		}
	}
	&__signature {
		position: relative;
		top: 10px;
		width: 62%;
		height: 2px;
		margin-top: 2%;
		margin-left: 12%;
		overflow: visible;
	}

	&__half {
		display: flex;
		flex-direction: row;
		align-items: flex-end;

		&__copyright {
			padding-bottom: 25px;
			width: 60%;
			&__text {
				font-size: 0.5em;
				letter-spacing: 1.3px;
				width: 90%;
				margin-left: 10%;
				text-align: center;
				margin-bottom: 4%;
			}
			&__line {
				border-bottom: 1px solid var(--sec-dark);
				width: 90%;
				margin-left: 10px;
			}
		}

		&__triangles {
			width: 40%;
		}
	}
}

/******************************************************************************************
********************************   MEDIA QUERIES   ****************************************
********************************************************************************************
********************************       TABLET       ****************************************
********************************************************************************************/
@media only screen and (min-width: 732px) {
	.Footer {
		&__part1 {
			&__intouch {
				&__like {
					font-size: 2.2em;
					font-weight: 700;
					padding-top: 4%;
				}
				&__talk {
					font-size: 1.4em;
				}
				&__button {
					margin-bottom: 6%;
					margin-top: 4%;
					&__btn {
						letter-spacing: 1.4px;
						font-size: 1.2em;
						padding: 5px 60px;
						margin: 3% auto;
					}
				}
			}
			&__resume {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				margin-left: 12%;
				margin: 6% auto;
				&__title {
					font-size: 1.3em;
					margin-bottom: 20px;
					margin-top: 20px;
					letter-spacing: 2px;
					margin-right: 25px;
				}
				&__link {
					margin-bottom: 0px;
					letter-spacing: 1px;
					font-size: 0.9em;
					width: 50%;
					&__doc {
						margin-right: 16px;
						padding: 3px;
					}
				}
			}
		}
		&__signature {
			position: relative;
			top: 15px;
			width: 52%;
			height: 2px;
			margin-top: 0%;
			margin-left: 12%;
			overflow: visible;
		}
		&__half {
			display: flex;
			flex-direction: row;
			align-items: flex-end;

			&__copyright {
				padding-bottom: 25px;
				width: 60%;
				&__text {
					font-size: 0.9em;
					letter-spacing: 1.6px;
					width: 90%;
					margin-left: 10%;
					text-align: center;
					margin-bottom: 4%;
				}
				&__line {
					border-bottom: 1px solid var(--sec-dark);
					width: 90%;
					margin-left: 10px;
				}
			}

			&__triangles {
				width: 30%;
				margin-left: 10%;
			}
		}
	}
}
/******************************************************************************************
********************************   MEDIA QUERIES   ****************************************
********************************************************************************************
********************************      DESKTOP   ****************************************__intouch
********************************************************************************************/
@media only screen and (min-width: 1130px) {
	.Footer {
		&__part1 {
			width: 80%;
			margin-left: 12%;
			&__intouch {
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				flex-wrap: wrap;
				padding-top: 3%;
				&__like {
					font-size: 2.4em;
					font-weight: 700;
					text-align: center;
					padding-top: 0%;
				}
				&__talk {
					font-size: 1.2em;
					font-weight: 500;
					letter-spacing: 1.6px;
				}
				&__button {
					margin-bottom: 5%;
					margin-top: 2%;
					&__btn {
						letter-spacing: 1.7px;
						font-size: 1.2em;
						padding: 6px 60px;
						margin: 3% auto;
					}
					&__btn:hover {
						cursor: pointer;
						background-color: #f49a9845;
						color: white;
						border: solid 2px #dc6969;
						box-shadow: 1px 1px 2px #f49a9870;
						transition: 0.2s ease-in;
					}
				}
			}
			&__resume {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-left: 0%;
				margin-top: 2%;
				margin-bottom: 6%;
				&__title {
					font-size: 2em;
					margin-bottom: 20px;
					margin-top: 20px;
					letter-spacing: 2.4px;
					margin-right: 10%;
				}
				&__link {
					margin-bottom: 0px;
					letter-spacing: 2.2px;
					font-size: 1.1em;

					&__doc {
						margin-right: 26px;
						padding: 3px;
						border-radius: 10px;
						transition: 0.3s ease-in;
					}

					&__doc:hover {
						color: var(--sec-dark);
						background-color: var(--sec-light);
						cursor: pointer;
						transition: 0.3s ease-out;
					}
				}
			}
		}
		&__signature {
			position: relative;
			top: 10px;
			width: 45%;
			height: 2px;
			margin-top: 2%;
			margin-left: 12%;
			overflow: visible;
		}

		&__half {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-end;
			&__copyright {
				padding-bottom: 15px;
				width: 100%;
				&__text {
					font-size: 1.1em;
					width: 90%;
					margin-left: 10%;
					text-align: center;
					margin-bottom: 2%;
				}
			}
			&__triangles {
				width: 25%;
				margin-left: 15%;
			}
		}
	}
}
