:root {
	--main-dark: #7382a2;
	--main-darker: #5a6988;
	--main-medium: #9cb8cc;
	--main-light: #c4e3ec;
	--sec-light: #f49998;
	--sec-dark: #dc6969;
	--bg-gradient: linear-gradient(to bottom, #d4e0e9, #c9d6de);
}
@import "./variables";

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;500;700&display=swap");

@font-face {
	font-family: "ShadowsIntoLight";
	src: url("../ressources/ShadowsIntoLight-Regular.ttf");
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
body {
	color: var(--dark-color);
	background: white;
	font-family: "Raleway", arial, helvetica, sans-serif;
}
.wrap {
	overflow-x: hidden;
}
.app {
	width: 100vw;
	height: 100%;
	overflow-x: hidden;
	overflow: hidden;
}
.bolder {
	font-weight: bold;
}

/******************* SCROLL BAR *********************/
html::-webkit-scrollbar {
	background-color: #c4e3ec;
	width: 1em;
}
html::-webkit-scrollbar-thumb {
	background-color: #9cb8cc;
	box-shadow: inset 4px 2px 6px #7382a2;
	border-radius: 6px;
}
html::-webkit-scrollbar-thumb:hover {
	background-color: #7382a2dd;
	box-shadow: inset 4px 2px 6px #535e78;
}
html::-webkit-scrollbar-track {
	background-color: #c4e3ec90;
	box-shadow: inset 0 0 6px #7382a2;
}
