@import "./variables";

.skills-landing {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: auto;
	height: 100vh;
	padding: 2%;
	background-color: #f7f5f9;
	background-image: $topo-background;
	overflow-x: hidden;
	&__box1 {
		width: 100%;
		& h1 {
			font-family: "ShadowsIntoLight";
			font-size: 4em;
			color: var(--main-dark);
			margin-top: 20%;
			margin-left: 10%;
			text-align: center;
			letter-spacing: 5px;
		}
		&__subtitle {
			font-family: "ShadowsIntoLight";
			font-weight: 700;
			letter-spacing: 1.5px;
			font-size: 1rem;
			color: var(--sec-dark);
			margin-top: 1%;
			padding-right: 10px;
			margin-left: 10%;
			text-align: center;
			word-spacing: 18px;
		}
	}
	&__box2 {
		font-weight: 500;
		background-color: #9cb8cc7c;
		border-radius: 10px;
		font-size: 0.7rem;
		color: var(--main-darker);
		letter-spacing: 2.2px;
		line-height: 22px;
		margin-bottom: 0px;
		padding: 4%;
		width: 90%;
		margin-left: 10%;
		margin-top: 6%;
	}
}
.panel {
	position: absolute;
	top: 40%;
	left: 60%;
	transform: translate(-50%, -50%);
	color: white;
	padding: 2% 6%;
	padding-bottom: 4%;
	border-radius: 10px;
	overflow-x: hidden;
	width: 95%;
	&__text {
		font-family: "ShadowsIntoLight";
		font-size: 2.6em;
		letter-spacing: 2.5px;
		margin-top: 10px;
	}
	&__text-undeline {
		display: flex;
		margin-right: 50px;
		font-weight: 500;
		font-size: 0.8rem;
		color: var(--sec-dark);
		margin-bottom: 0.2%;
		letter-spacing: 2px;
		line-height: 22px;
	}
	&__text-undeline p {
		margin-right: 12px;
	}
	&__logo {
		position: relative;
		top: -10vh;
		left: 30px;
	}
}
.panel-last-child {
	padding: 2% 1%;
}
.blue-text {
	color: var(--main-dark);
}
.blue-light-text {
	color: var(--main-light);
}
.red-light-text {
	color: var(--sec-light);
}
.red-text {
	color: var(--sec-dark);
}
.white-text {
	color: white;
}
.icons-rows {
	margin-right: 0px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	color: var(--main-dark);
	font-size: 1.4em;
}
.last-skills-columns {
	margin-top: 20px;
}
.first-panel {
	padding: 30px;
	margin-top: 30px;
}

.Stars-container {
	border-top: 1px solid white;
	border-left: 1px solid white;
	width: 100%;
	margin-top: 4%;
	padding-top: 2%;

	&__rows {
		font-size: 0.75em;
		display: flex;
		align-items: center;
		margin-left: 10px;
		margin-top: 0px;
	}
	&__icons {
		font-size: 1.4em;
	}
}
.block-half {
	width: 100%;
}
.block-ctn {
	display: flex;
	flex-direction: column;
}
.Stars {
	display: flex;
	justify-content: left;
	padding: 1.5% 0;
	&__title {
		padding-right: 20px;
		padding-left: 20px;
		font-family: "ShadowsIntoLight";
		font-size: 1rem;
		letter-spacing: 2px;
	}
	&__icons {
		margin-right: 10px;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}
}
.panel1 {
	position: absolute;
	background-color: #7382a2;
	background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23c4e3ec' fill-opacity='0.3' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
	width: 100vw;
	height: 120vh;
}
.panel2 {
	position: absolute;
	background-color: #9cb8cc;
	background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23c4e3ec' fill-opacity='0.3' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
	width: 100vw;
	height: 120vh;
}
.panel3 {
	position: absolute;
	width: 100vw;
	height: 120vh;
	z-index: 10;
	background-color: var(--main-light);
	background-image: $topo-background;
}
.bottom-skills {
	position: relative;
	bottom: 0;
	width: 100vw;
	height: 130vh;
	z-index: -10;
	background-color: var(--main-light);
	background-image: $topo-background;
}
.flags svg {
	width: 18px;
	margin-top: 4px;
}

/******************************************************************************************
********************************   MEDIA QUERIES   ****************************************
********************************************************************************************
********************************     EXTAR SMALL     ****************************************
********************************************************************************************/
@media only screen and (max-width: 332px) {
	.skills-landing__box2 {
		line-height: 18px;
		font-size: 0.6em;
	}
	.panel {
		position: absolute;
		top: 40%;
		left: 60%;
		transform: translate(-50%, -50%);
		&__text {
			font-size: 2.5em;
			letter-spacing: 2.3px;
		}
		&__text-undeline {
			font-weight: 500;
			font-size: 0.8rem;
			margin-bottom: 1%;
			letter-spacing: 2px;
			line-height: 20px;
		}
	}
	.Stars {
		&__title {
			padding-right: 20px;
			padding-left: 20px;
			font-family: "ShadowsIntoLight";
			font-size: 0.8em;
			letter-spacing: 1.2px;
		}
		&__icons {
			margin-right: 8px;
			display: flex;
		}
	}
}
/******************************************************************************************
********************************   MEDIA QUERIES   ****************************************
********************************************************************************************
********************************       TABLET       ****************************************
********************************************************************************************/
@media only screen and (min-width: 732px) {
	.panel1 {
		height: 100vh;
		width: 100vw;
	}
	.panel2 {
		height: 100vh;
		width: 100vw;
	}
	.panel3 {
		height: 100vh;
		width: 100vw;
	}
	.skills-landing {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		overflow-y: hidden;
		&__box1 {
			margin-left: 0%;
			width: 100%;
			& h1 {
				font-size: 6em;
				margin-left: 12vw;
				margin-top: 8%;
				text-align: left;
			}
			&__subtitle {
				font-weight: 700;
				letter-spacing: 1.8px;
				font-size: 1.2rem;
				margin-top: 10px;
				padding-right: 10px;
				margin-left: 12vw;
				text-align: left;
			}
		}
		&__box2 {
			font-weight: 500;
			font-size: 0.8rem;
			letter-spacing: 2.2px;
			line-height: 28px;
			margin-bottom: 10px;
			padding: 4%;
			width: 78%;
			margin-top: 5%;
			margin-left: 4%;
		}
	}
	.panel {
		position: absolute;
		top: 40%;
		left: 55%;
		transform: translate(-50%, -50%);
		padding: 2% 8%;
		padding-bottom: 4%;
		border-radius: 10px;
		width: 90%;
		&__text {
			font-size: 3.8em;
			letter-spacing: 2.5px;
			margin-top: 20%;
		}
		&__text-undeline {
			display: flex;
			flex-wrap: wrap;
			font-size: 1.1em;
			font-weight: 700;
			margin-bottom: 3%;
			letter-spacing: 2px;
			line-height: 24px;
			& p {
				margin-right: 30px;
			}
		}
	}
	.panel-last-child {
		padding: 2% 1%;
	}
	.white-text {
		color: white;
	}
	.icons-rows {
		margin-right: 1px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 1.7em;
	}
	.last-skills-columns {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;
	}
	.first-panel {
		padding: 30px;
		margin-top: 30px;
	}
	.Stars-container {
		width: 95%;
		margin-top: 5%;
		padding-top: 3%;
		&__rows {
			display: flex;
			align-items: center;
			margin-left: 20px;
			margin-right: 10%;
			margin-bottom: 3%;
		}
		&__icons {
			font-size: 1.7em;
		}
	}
	.Stars {
		display: flex;
		justify-content: left;
		padding: 2% 0;
		&__title {
			margin-right: 5px;
			margin-left: 5px;
			font-size: 1rem;
		}
		&__icons {
			margin-right: 10px;
			display: flex;
			justify-content: space-evenly;
			align-items: center;
		}
	}
	.block-half {
		width: 60%;
	}
	.block-ctn {
		display: flex;
		flex-direction: row;
	}
}
/******************************************************************************************
********************************   MEDIA QUERIES   ****************************************
********************************************************************************************
********************************      DESKTOP     ****************************************
********************************************************************************************/

@media only screen and (min-width: 1130px) {
	.panel1 {
		height: 100vh;
		width: 100vw;
	}
	.panel2 {
		height: 100vh;
		width: 100vw;
	}
	.panel3 {
		height: 100vh;
		width: 100vw;
	}
	.skills-landing {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		flex-direction: row;
		&__box1 {
			margin-left: 11%;
			width: 100%;
			& h1 {
				font-size: 10em;
				margin-left: -2%;
				margin-top: 10px;
				text-align: left;
				padding-right: 20px;
			}
			&__subtitle {
				font-weight: 700;
				letter-spacing: 2.2px;
				font-size: 1.3rem;
				margin-top: 10px;
				padding-right: 2px;
				margin-left: -10%;
				text-align: center;
			}
		}
		&__box2 {
			font-weight: 500;
			font-size: 1rem;
			letter-spacing: 2.5px;
			line-height: 30px;
			margin-bottom: 10px;
			padding: 4%;
			width: 100%;
			margin-top: 5%;
			margin-left: 0;
		}
	}
	.panel {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 2% 8%;
		padding-bottom: 4%;
		border-radius: 10px;
		width: 80%;
		&__text {
			margin-top: 0%;
			font-size: 4em;
		}
		&__text-undeline {
			font-size: 1.1rem;
			margin-bottom: 10px;
		}
		&__title {
			font-size: 4em;
		}
		&__title-underline {
			font-size: 1.3rem;
			margin-bottom: 10px;
		}
		&__title-text {
			font-size: 1.1rem;
			letter-spacing: 3px;
			line-height: 34px;
			margin-bottom: 10px;
		}
		&__logo {
			position: relative;
			top: -10vh;
			left: 30px;
		}
	}
	.panel-last-child {
		padding: 2% 1%;
	}
	.white-text {
		color: white;
	}
	.icons-rows {
		margin-right: 1px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 1.9em;
	}
	.last-skills-columns {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;
	}
	.first-panel {
		padding: 30px;
		margin-top: 30px;
	}
	.block-half {
		width: 60%;
	}
	.block-ctn {
		display: flex;
		flex-direction: row;
	}
	.Stars-container {
		width: 80%;
		padding-top: 15px;
		&__rows {
			display: flex;
			align-items: center;
			margin-left: 20px;
			margin-right: 10%;
		}
		&__icons {
			font-size: 1.9em;
		}
	}
	.Stars {
		display: flex;
		justify-content: left;
		padding: 2% 0;
		&__title {
			margin-right: 10px;
			margin-left: 10px;
			font-size: 1rem;
		}
		&__icons {
			margin-right: 10px;
			display: flex;
			justify-content: space-evenly;
			align-items: center;
		}
	}
}
