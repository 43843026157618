/***********************  LANDING PAGE *********************/
.Landing {
	height: 100vh;
	width: 100vw;
	z-index: 1;
	&__background-image {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 180%;
		max-height: 100vh;
		z-index: 1;
	}
	&__text {
		position: absolute;
		top: 18%;
		left: 20vw;
		width: 70%;
		height: 80%;
		display: flex;
		flex-direction: column;
		z-index: 20;
		&__title {
			font-size: 2.5em;
			color: var(--main-darker);
			font-weight: 700;
			line-height: 56px;
			word-spacing: 1px;
			letter-spacing: 4px;
			font-family: "ShadowsIntoLight";
		}
		&__subtitle {
			font-size: 1em;
			color: var(--sec-dark);
			font-weight: 500;
			letter-spacing: 1.7px;
			margin: 12% auto;
			line-height: 26px;
			word-spacing: 0px;
		}
		&__buttons {
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			align-items: center;
			margin: 1% auto;
			&__btn {
				background-color: #7382a284;
				color: white;
				font-family: "ShadowsIntoLight";
				letter-spacing: 1.2px;
				font-size: 1.1em;
				border: solid 1px white;
				border-radius: 20px;
				padding: 3px 40px;
				box-shadow: 6px 8px 5px #7382a231;
				transition: 0.3s ease-out;
				margin: 6% auto;
			}
			&__btn:hover {
				cursor: pointer;
				background-color: #7382a231;
				color: var(--main-dark);
				border: solid 1px var(--main-dark);
				box-shadow: 1px 2px 2px #7382a231;
				transition: 0.2s ease-in;
			}
		}
	}
}
.orb-canvas {
	position: scroll;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	pointer-events: none;
	z-index: 2;
	background: var(--bg-gradient);
}
.layered-waves svg {
	position: absolute;
	top: 100vh;
	width: 100%;
	height: auto;
	z-index: 0;
}
/***********************  HOME PAGE *********************/
.home {
	position: relative;
	top: -90vh;
	left: 0;
	width: 100vw;
	&__welcome {
		display: flex;
		align-items: center;
		flex-direction: column-reverse;
		justify-content: space-around;
		margin-top: 2%;
		margin-bottom: 2%;
		&__photo {
			width: 40%;
			margin-bottom: 5%;
			margin-top: 3%;
		}
		&__photo img {
			width: 100%;
			border-radius: 15%;
			border: 2px solid var(--main-dark);
			box-shadow: 6px 6px 4px #3c637d70;
		}
		& p {
			color: var(--main-darker);
			width: 75%;
			line-height: 24px;
			letter-spacing: 1.2px;
			margin-top: 0%;
			margin-bottom: 3%;
			margin-left: 20%;
			font-weight: 500;
			font-size: 0.8em;
		}
	}
	&__featured {
		padding-top: 6%;
		&__text {
			position: relative;
			top: 50%;
			left: 20%;
			&__title {
				color: var(--sec-dark);
				font-size: 1.5em;
				letter-spacing: 1.8px;
				margin-bottom: 4%;
				font-weight: 700;
				font-family: "ShadowsIntoLight";
			}
			&__subtitle {
				color: var(--main-darker);
				width: 60%;
				font-size: 0.9em;
				margin-bottom: 20px;
				line-height: 26px;
				font-weight: 400;
			}
			&__polygone1 {
				width: 300px;
				height: auto;
				position: absolute;
				top: -20%;
				left: -30%;
				z-index: -1;
			}
		}
		&__visuel {
			display: flex;
			justify-content: flex-end;
			height: auto;
			width: 100%;
			object-fit: cover;
			margin-bottom: 4%;
		}
		&__buttons {
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			align-items: center;
			flex-wrap: wrap;
			margin-bottom: 6%;
			margin-top: 12%;
			width: 80%;
			margin-left: 20%;
			&__btn {
				background-color: #f49a9841;
				color: #dc6969;
				font-family: "ShadowsIntoLight";
				letter-spacing: 1px;
				font-size: 0.7em;
				border: solid 1px #dc6969;
				border-radius: 20px;
				padding: 2px 20px;
				box-shadow: 2px 4px 8px #f49a9870;
				transition: 0.3s ease-out;
				margin: 2% auto;
			}
			&__btn:hover {
				cursor: pointer;
				background-color: #f49a98b1;
				color: white;
				border: solid 1px #dc6969;
				box-shadow: 1px 1px 2px #f49a9870;
				transition: 0.2s ease-in;
			}
		}
	}
	&__projects {
		&__proj {
			position: relative;
			top: 50%;
			left: 20%;
			margin-top: 10%;
			&__title {
				color: var(--sec-dark);
				font-size: 1.5em;
				letter-spacing: 1.8px;
				margin-bottom: 4%;
				font-weight: 700;
				font-family: "ShadowsIntoLight";
			}
			&__subtitle {
				color: var(--main-darker);
				width: 60%;
				font-size: 0.9em;
				margin-bottom: 20px;
				line-height: 26px;
				font-weight: 500;
			}
			&__polygone {
				width: 300px;
				height: auto;
				position: absolute;
				top: -20%;
				left: -30%;
				z-index: -1;
			}
		}
		&__image {
			width: 80%;
			margin-left: 12%;
			opacity: 0.9;
			transition: 0.8s ease-in;
		}
		&__image:hover {
			opacity: 1.1;
			transition: 0.3s ease-in;
		}
		&__buttons {
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			align-items: center;
			flex-wrap: wrap;
			margin-bottom: 25%;
			margin-top: 4%;
			width: 80%;
			margin-left: 13%;
			&__btn {
				background-color: #f49a9841;
				color: #dc6969;
				font-family: "ShadowsIntoLight";
				letter-spacing: 1px;
				font-size: 0.8em;
				border: solid 1px #dc6969;
				border-radius: 20px;
				padding: 2px 20px;
				box-shadow: 2px 4px 8px #f49a9870;
				transition: 0.3s ease-out;
				margin: 2% auto;
			}
			&__btn:hover {
				cursor: pointer;
				background-color: #f49a98b1;
				color: white;
				border: solid 1px #dc6969;
				box-shadow: 1px 1px 2px #f49a9870;
				transition: 0.2s ease-in;
			}
		}
		&__large-button {
			width: 80%;
			margin-left: 15%;
			margin-top: 0%;
			margin-bottom: 15%;
			text-align: center;
			&__btn {
				padding: 10px 20px;
				color: #7382a2;
				background: #7382a25b;
				border: solid 1px #7382a2;
				border-radius: 20px;
				padding: 8px 40px;
				box-shadow: 2px 4px 10px #7382a2;
				font-family: "ShadowsIntoLight";
				letter-spacing: 1.1px;
				font-size: 1.2em;
				transition: 0.3s ease-out;
			}
			&__btn:hover {
				cursor: pointer;
				background-color: #7382a2c9;
				color: white;
				box-shadow: 1px 1px 2px #7382a276;
				transition: 0.2s ease-in;
			}
		}
	}
	&__skills {
		margin-top: 0%;
		margin-left: 0%;
		margin-bottom: 6%;
		width: 100vw;
		&__techno {
			height: 90vh;
			width: 100%;
			background: var(--sec-light);
			margin-bottom: 0%;
			margin-top: -12%;
			margin-right: 0%;
			z-index: 2;
			&__ctn {
				height: 100%;
				width: 100%;
				background: var(--sec-light);
				background-repeat: no-repeat;
				background-attachment: fixed;

				//background-attachment: scroll;
				background-size: contain;
			}
		}
	}
	&__about {
		margin-bottom: -85vh;
		&__half {
			&__triptic {
				margin-top: -28%;
				margin-bottom: -18%;
				width: 100vw;
				margin-left: 5vw;
			}
			&__text {
				font-weight: 500;
				padding-left: 20%;
				padding-right: 10%;
				letter-spacing: 2px;
				line-height: 25px;
				&__first {
					color: var(--main-darker);
					margin-bottom: 20px;
				}
				&__second {
					color: var(--sec-dark);
					margin-bottom: 20px;
				}
				&__second-last {
					color: var(--sec-dark);
					margin-bottom: 25%;
				}
			}
		}
	}
	&__arrow {
		display: flex;
		align-items: center;
		margin-left: 18vw;
		text-decoration: none;
		margin-top: 0%;
	}
}
.chevron {
	display: flex;
	&__left {
		position: relative;
		top: 40vh;
		left: 7.5%;
		width: 7%;
		height: 7%;
	}
	&__right {
		position: relative;
		top: 40vh;
		left: 80%;
		width: 7%;
		height: 7%;
	}
	&__right:hover,
	&__left:hover {
		cursor: pointer;
		& svg g path {
			fill: #7382a2;
		}
	}
}
/******************************************************************************************
********************************   MEDIA QUERIES   ****************************************
********************************************************************************************
********************************     EXTAR SMALL     ****************************************
********************************************************************************************/
@media only screen and (max-width: 332px) {
	.Landing {
		&__text {
			&__title {
				font-size: 2em;
				font-weight: 700;
				line-height: 48px;
				word-spacing: 1px;
				letter-spacing: 4px;
			}
			&__subtitle {
				font-size: 0.9em;
				font-weight: 500;
				letter-spacing: 1.4px;
				margin: 10% auto;
				line-height: 24px;
			}
		}
	}
}
/******************************************************************************************
********************************   MEDIA QUERIES   ****************************************
********************************************************************************************
********************************       TABLET       ****************************************
********************************************************************************************/
@media only screen and (min-width: 732px) {
	/***********************  Landing background *********************/
	.Landing {
		&__background-image {
			width: 140%;
		}
		&__text {
			position: absolute;
			top: 20%;
			left: 19vw;
			width: 75%;
			height: 80%;
			max-height: 80vh;
			&__title {
				font-size: 3.5em;
				line-height: 78px;
				word-spacing: 2px;
				letter-spacing: 5px;
			}
			&__subtitle {
				font-size: 1.1em;
				letter-spacing: 2.7px;
				margin: 3% auto;
				line-height: 40px;
				word-spacing: 5px;
				padding-right: 30px;
				padding-right: 15%;
			}
			&__buttons {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				width: 65%;
				margin-left: 0%;
				&__btn {
					cursor: pointer;
					letter-spacing: 2.7px;
					font-size: 1.2em;
					padding: 5px 50px;
					margin: 2% auto;
					margin-right: 10%;
				}
			}
		}
	}
	.home {
		position: relative;
		top: -80vh;
		left: 0;
		width: 100vw;
		&__welcome {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row;
			margin-top: 2%;
			margin-bottom: 2%;
			&__photo {
				width: 40%;
				margin-bottom: 0%;
				margin-left: 10%;
				margin-top: 0%;
			}
			&__photo img {
				width: 100%;
				border-radius: 15%;
				border: 2px solid var(--main-dark);
				box-shadow: 6px 6px 4px #3c637d70;
			}
			& p {
				color: var(--main-darker);
				width: 80%;
				line-height: 34px;
				letter-spacing: 1.5px;
				margin-top: 0%;
				margin-bottom: 0%;
				margin-left: 12%;
				font-weight: 500;
			}
		}
		&__featured {
			&__text {
				margin-top: 4%;
				position: relative;
				top: -20px;
				left: 26vw;
				&__title {
					font-size: 1.7em;
					letter-spacing: 2.3px;
					margin-bottom: 2%;
				}
				&__subtitle {
					width: 60%;
					font-size: 1.1em;
					margin-bottom: 20px;
					line-height: 40px;
					word-spacing: 2px;
					letter-spacing: 1.4px;
				}
				&__polygone1 {
					width: 300px;
					height: auto;
					position: absolute;
					top: -8vh;
					left: -16vw;
					z-index: -1;
				}
			}
			&__visuel {
				display: flex;
				justify-content: flex-end;
				height: auto;
				width: 80%;
				margin-left: 20%;
				object-fit: contain;
				margin-bottom: 1%;
				position: relative;
				top: 0vh;
				z-index: 0;
			}
			&__buttons {
				width: 60%;
				margin-left: 30%;
				display: flex;
				flex-direction: row;
				justify-content: space-evenly;
				margin-bottom: 6%;
				margin-top: 1%;
				z-index: 100;
				&__btn {
					letter-spacing: 1.2px;
					font-size: 1em;
					padding: 4px 40px;
					box-shadow: 2px 4px 2px #f49a9870;
					margin: 3% auto;
				}
				&__btn:hover {
					cursor: pointer;
					border: solid 1px #dc6969;
					box-shadow: 1px 1px 2px #f49a9870;
					transition: 0.2s ease-in;
				}
			}
		}
		&__projects {
			&__half {
				display: flex;
				flex-direction: row-reverse;
			}
			&__proj {
				position: relative;
				top: 50%;
				left: 10%;
				margin-top: -0.3%;
				&__title {
					color: var(--sec-dark);
					font-size: 1.7em;
					letter-spacing: 2.3px;
					margin-bottom: 4%;
					font-weight: 700;
				}
				&__subtitle {
					color: var(--main-darker);
					width: 75%;
					font-size: 1em;
					letter-spacing: 1.1px;
					margin-bottom: 10px;
					line-height: 30px;
					font-weight: 400;
					padding-right: 10px;
				}
				&__polygone {
					width: 250px;
					height: auto;
					position: absolute;
					top: -20%;
					left: -15%;
					z-index: -1;
				}
			}
			&__image {
				object-fit: contain;
				width: 38%;
				margin-left: 8%;
			}
			&__buttons {
				display: flex;
				flex-direction: row;
				justify-content: space-evenly;
				align-items: center;
				margin-bottom: 18%;
				margin-top: -1%;
				width: 46%;
				margin-left: 50%;
				&__btn {
					cursor: pointer;
					background-color: #f49a9841;
					color: #dc6969;
					font-family: "ShadowsIntoLight";
					letter-spacing: 1.1px;
					font-size: 0.9em;
					border: solid 1px #dc6969;
					border-radius: 20px;
					padding: 3px 30px;
					box-shadow: 2px 4px 8px #f49a9870;
					transition: 0.3s ease-out;
					margin: 2% auto;
				}
				&__btn:hover {
					cursor: pointer;
					background-color: #f49a98b1;
					color: white;
					border: solid 1px #dc6969;
					box-shadow: 1px 1px 2px #f49a9870;
					transition: 0.2s ease-in;
				}
			}
			&__large-button {
				width: 60%;
				margin-left: 20%;
				margin-top: -5%;
				margin-bottom: 15%;
				text-align: center;
				&__btn {
					padding: 10px 20px;
					color: #7382a2;
					background: #7382a25b;
					border: solid 1px #7382a2;
					border-radius: 20px;
					padding: 10px 50px;
					box-shadow: 2px 4px 10px #7382a2;
					font-family: "ShadowsIntoLight";
					letter-spacing: 1.2px;
					font-size: 1.2em;
					transition: 0.3s ease-out;
				}
				&__btn:hover {
					cursor: pointer;
					cursor: pointer;
					background-color: #7382a2c9;
					color: white;
					box-shadow: 1px 1px 2px #7382a276;
					transition: 0.2s ease-in;
				}
			}
		}
		&__skills {
			margin-top: -2%;
		}
		&__about {
			margin-top: 0%;
			margin-bottom: -70vh;
			&__half {
				display: flex;
				justify-content: center;
				align-items: flex-start;
				margin-top: -3%;
				&__triptic {
					margin-top: -12%;
					width: 100%;
					height: 100%;
					margin-left: 0px;
					margin-bottom: 0%;
				}
				&__text {
					width: 100%;
					margin-left: -8%;
					font-weight: 500;
					font-size: 0.8em;
					padding-left: 0px;
					padding-right: 5%;
					letter-spacing: 2px;
					line-height: 24px;
					margin-bottom: 6%;
					&__first {
						color: var(--main-darker);
						margin-bottom: 20px;
					}
					&__second {
						color: var(--sec-dark);
					}
					&__second-last {
						color: var(--sec-dark);
						margin-bottom: 20%;
					}
				}
			}
		}
		&__arrow {
			display: flex;
			align-items: center;
			margin-left: 18vw;
			text-decoration: none;
		}
	}
	.chevron {
		display: flex;
		&__left {
			position: relative;
			top: 30vh;
			left: 5%;
			width: 5%;
			height: 5%;
		}
		&__right {
			position: relative;
			top: 30vh;
			left: 87%;
			width: 5%;
			height: 5%;
		}
		&__right:hover,
		&__left:hover {
			cursor: pointer;
			& svg g path {
				fill: #7382a2;
			}
		}
	}
}
/******************************************************************************************
********************************   MEDIA QUERIES   ****************************************
********************************************************************************************
********************************      DESKTOP   ****************************************
********************************************************************************************/
@media only screen and (min-width: 1130px) {
	/***********************  Landing background *********************/
	.Landing {
		&__background-image {
			width: auto;
		}
		&__text {
			position: absolute;
			top: 20%;
			left: 12vw;
			width: 60%;
			height: 80%;
			&__title {
				font-size: 4.2em;
				line-height: 88px;
				word-spacing: 20px;
				letter-spacing: 4px;
			}
			&__subtitle {
				font-size: 1.2em;
				letter-spacing: 2.7px;
				margin: 2% auto;
				line-height: 36px;
				word-spacing: 5px;
				padding-right: 10%;
			}
			&__buttons {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				width: 70%;
				&__btn {
					cursor: pointer;
					letter-spacing: 2.7px;
					font-size: 1.5em;
					padding: 6px 50px;
					margin: 5% auto;
					margin-right: 10%;
				}
			}
		}
	}
	.home {
		position: relative;
		top: -80vh;
		left: 0;
		width: 100vw;
		&__welcome {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row;
			margin-top: 5%;
			margin-bottom: 2%;
			&__photo {
				width: 25%;
				margin-bottom: 0%;
				margin-top: 0%;
			}
			&__photo img {
				width: 100%;
				border-radius: 15%;
				border: 2px solid var(--main-dark);
				box-shadow: 6px 6px 4px #3c637d70;
			}
			& p {
				color: var(--main-darker);
				width: 80%;
				line-height: 38px;
				letter-spacing: 1.7px;
				margin-top: 0%;
				margin-bottom: 0%;
				margin-left: 12%;
				font-weight: 500;
			}
		}
		&__featured {
			height: 120vh;
			background-position: right;
			background-size: cover;
			background-repeat: no-repeat;
			margin-top: -10%;
			margin-bottom: 2%;
			&__text {
				position: relative;
				top: 0px;
				left: 12vw;
				margin-top: 1%;
				&__title {
					font-size: 1.9em;
					letter-spacing: 2.6px;
					margin-bottom: 2%;
				}
				&__subtitle {
					width: 35%;
					font-size: 1.2em;
					margin-bottom: 20px;
					line-height: 45px;
					word-spacing: 3px;
					letter-spacing: 1px;
				}
				&__polygone1 {
					width: 300px;
					height: auto;
					position: absolute;
					top: -8vh;
					left: -8vw;
					z-index: -1;
				}
			}
			&__buttons {
				width: 40%;
				margin-left: 10%;
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: flex-start;
				margin-bottom: 0%;
				margin-top: 3%;
				&__btn {
					letter-spacing: 1.2px;
					font-size: 1.2em;
					padding: 4px 60px;
					box-shadow: 2px 4px 2px #f49a9870;
					margin: 20px 20px;
				}
				&__btn:hover {
					cursor: pointer;
					border: solid 1px #dc6969;
					box-shadow: 1px 1px 2px #f49a9870;
					transition: 0.2s ease-in;
				}
			}
		}
		&__projects {
			&__half {
				display: flex;
				flex-direction: row-reverse;
			}
			&__proj {
				position: relative;
				top: 50%;
				left: 12%;
				margin-top: -1%;
				&__title {
					color: var(--sec-dark);
					font-size: 1.9em;
					letter-spacing: 2.6px;
					margin-bottom: 4%;
					font-weight: 700;
				}
				&__subtitle {
					color: var(--main-darker);
					width: 60%;
					font-size: 1.1em;
					margin-bottom: 3%;
					line-height: 40px;
					font-weight: 500;
					letter-spacing: 2.2px;
				}
				&__polygone {
					width: 250px;
					height: auto;
					position: absolute;
					top: -20%;
					left: -15%;
					z-index: -1;
				}
			}
			&__image {
				width: 30%;
				margin-left: 12%;
			}
			&__buttons {
				display: flex;
				flex-direction: row;
				justify-content: space-evenly;
				align-items: center;
				margin-bottom: 14%;
				margin-top: -1%;
				width: 42%;
				margin-left: 50%;
				&__btn {
					background-color: #f49a9841;
					color: #dc6969;
					font-family: "ShadowsIntoLight";
					letter-spacing: 1.2px;
					font-size: 1.1em;
					border: solid 1px #dc6969;
					border-radius: 20px;
					padding: 3px 40px;
					box-shadow: 2px 4px 8px #f49a9870;
					transition: 0.3s ease-out;
					margin: 2% auto;
				}
				&__btn:hover {
					cursor: pointer;
					background-color: #f49a98b1;
					color: white;
					border: solid 1px #dc6969;
					box-shadow: 1px 1px 2px #f49a9870;
					transition: 0.2s ease-in;
				}
			}
			&__large-button {
				width: 60%;
				margin-left: 20%;
				margin-top: -7%;
				margin-bottom: 10%;
				text-align: center;
				&__btn {
					padding: 10px 20px;
					color: #7382a2;
					background: #7382a25b;
					border: solid 1px #7382a2;
					border-radius: 20px;
					padding: 10px 50px;
					box-shadow: 2px 4px 10px #7382a2;
					font-family: "ShadowsIntoLight";
					letter-spacing: 1.2px;
					font-size: 1.2em;
					transition: 0.3s ease-out;
				}
				&__btn:hover {
					cursor: pointer;
					background-color: #7382a2c9;
					color: white;
					box-shadow: 1px 1px 2px #7382a276;
					transition: 0.2s ease-in;
				}
			}
		}
		&__skills {
			margin-top: -2%;
			margin-left: 0%;
			margin-bottom: 6%;
			width: 100vw;
			&__techno {
				height: 90vh;
				width: 100%;
				background: var(--sec-light);
				margin-bottom: 0%;
				margin-top: -12%;
				margin-right: 0%;
				z-index: 2;
				&__ctn {
					height: 100%;
					width: 100%;
					background: var(--sec-light);
					background-repeat: no-repeat;
					background-attachment: fixed;
					//background-attachment: scroll;
					background-size: contain;
				}
			}
		}
		&__about {
			margin-top: 0%;
			margin-bottom: -70vh;
			&__half {
				display: flex;
				justify-content: center;
				align-items: flex-start;
				margin-top: -3%;
				&__triptic {
					margin-top: -12%;
					width: 100%;
					height: 100%;
					margin-left: 0px;
					margin-bottom: 0%;
				}
				&__text {
					width: 100%;
					margin-left: -8%;
					font-weight: 500;
					font-size: 1.1em;
					padding-left: 0px;
					padding-right: 5%;
					letter-spacing: 2px;
					line-height: 34px;
					margin-bottom: 6%;
					&__first {
						color: var(--main-darker);
						margin-bottom: 20px;
					}
					&__second {
						color: var(--sec-dark);
					}
					&__second-last {
						color: var(--sec-dark);
						margin-bottom: 10%;
					}
				}
			}
		}
		&__arrow {
			display: flex;
			align-items: center;
			margin-left: 18vw;
			text-decoration: none;
		}
	}
	.chevron {
		display: flex;
		&__left {
			position: relative;
			top: 25vh;
			left: 5%;
			width: 5%;
			height: 5%;
		}
		&__right {
			position: relative;
			top: 25vh;
			left: 87%;
			width: 5%;
			height: 5%;
		}
		&__right:hover,
		&__left:hover {
			cursor: pointer;
			& svg g path {
				fill: #7382a2;
			}
		}
	}
}
.mt-10 {
	margin-top: -10%;
}
