.container {
	width: 70%;
	margin-left: 15%;
	padding-top: 2%;
	height: 90%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	z-index: 4;
	opacity: 1;
}
.container .card {
	width: 100%;
	height: 100%;
	margin: 10px;
	box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.5);
	border-radius: 15px;
	background: rgba(255, 255, 255, 0.1);
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 1px solid rgba(255, 255, 255, 0.5);
	border-left: 1px solid rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(5px);
}
.container .card .content {
	padding: 12px;
	text-align: center;
	transition: 0.5s;
}
.container .card .content h2 {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 5em;
	color: rgba(230, 94, 94, 0.461);
	pointer-events: none;
	padding: 5px;
}
.container .card .content h3 {
	text-align: left;
	margin-left: 5%;
	font-size: 2em;
	color: var(--main-dark);
	font-family: "ShadowsIntoLight";
	letter-spacing: 3px;
}
.container .card .content p {
	font-size: 0.9em;
	font-weight: 500;
	margin: 20px 10px;
	color: var(--main-darker);
	line-height: 22px;
}
.container .card .content a {
	position: relative;
	display: inline-block;
	padding: 10px 30px;
	margin-top: 2px;
	font-size: 0.9em;
	background-color: rgba(230, 94, 94, 0.529);
	color: var(--main-darker);
	text-decoration: none;
	border-radius: 20px;
	font-weight: 600;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
	letter-spacing: 1.8px;
	font-family: "ShadowsIntoLight";
	transition: 0.4s ease-in;
}
.container .card .content a:hover {
	color: var(--main-darker);
	background-color: rgba(230, 94, 94, 0.235);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
	transition: 0.3s ease-out;
	border: 1px solid var(--main-darker);
	font-weight: 500;
}

/******************************************************************************************
********************************   MEDIA QUERIES   ****************************************
********************************************************************************************
********************************       TABLET       ****************************************
********************************************************************************************/
@media only screen and (min-width: 732px) {
	.container {
		width: 70%;
		margin-left: 15%;
		height: 90%;
	}
	.container .card .content h2 {
		font-size: 7em;
		padding: 16px;
	}
	.container .card .content h3 {
		text-align: left;
		margin-left: 8%;
		font-size: 5em;
		letter-spacing: 4px;
	}
	.container .card .content p {
		font-size: 1.2em;
		font-weight: 500;
		margin: 20px 10px;
		line-height: 35px;
		letter-spacing: 1.5px;
	}
	.container .card .content a {
		padding: 10px 30px;
		margin-top: 5px;
		font-size: 1em;
		border-radius: 20px;
		font-weight: 600;
		letter-spacing: 2.3px;
	}
	.container .card .content a:hover {
		font-weight: 500;
	}
}
/******************************************************************************************
********************************   MEDIA QUERIES   ****************************************
********************************************************************************************
********************************      DESKTOP   ****************************************
********************************************************************************************/
@media only screen and (min-width: 1130px) {
	.container {
		width: 60%;
		margin-left: 20%;
		height: 90%;
	}
	.container .card .content h2 {
		font-size: 8em;
		padding: 16px;
	}
	.container .card .content h3 {
		text-align: center;
		margin-left: 0%;
		font-size: 5em;
		letter-spacing: 4px;
	}
	.container .card .content p {
		font-size: 1.3em;
		font-weight: 500;
		margin: 20px 10px;
		line-height: 35px;
		letter-spacing: 2px;
	}
	.container .card .content a {
		padding: 10px 30px;
		margin-top: 10px;
		font-size: 1.1em;
		border-radius: 20px;
		font-weight: 600;
		letter-spacing: 2.5px;
	}
	.container .card .content a:hover {
		font-weight: 500;
	}
}
