@import "./variables";

.OpenedMenu {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 30;
	clip-path: circle(100%);
	background-color: #9cb8cc;
	background-image: $topo-background-menu;
	&__link {
		color: var(--sec-dark);
		font-size: 3em;
		text-decoration: none;
		display: flex;
		justify-content: center;
		margin-top: 5%;
		margin-left: 20%;
		padding: 5px;
		width: 60%;
	}
}
.active-open {
	border: 1px solid var(--sec-dark);
}
/*-----------------  LOGO  -----------------------------*/
.logo-svg svg {
	position: absolute;
	top: 1%;
	left: 1%;
	z-index: 30;
	height: 9%;
}
.logo:hover {
	cursor: pointer;
}
@media only screen and (min-width: 576px) {
	.logo-svg svg {
		position: absolute;
		top: 0;
		left: 3vw;
		width: 25%;
		height: 15%;
		z-index: 10;
	}
}
@media only screen and (min-width: 1130px) {
	.logo-svg svg {
		position: absolute;
		top: 0;
		left: 2vw;
		width: 20%;
		height: 15%;
		z-index: 10;
	}
}

/*-------------------------    TOP MENU  --------------------------------------*/
.TopMenu {
	position: absolute;
	top: 1%;
	right: 2vw;
	display: flex;
	justify-content: center;
	z-index: 40;
	&__link {
		text-decoration: none;
		padding: 5px 2vw;
		color: var(--sec-dark);
		font-size: 1.6em;
		font-family: "ShadowsIntoLight";
		letter-spacing: 4px;
		font-weight: bold;
		transition: 0.3s ease-in;
		border: 2px solid transparent;
		border-radius: 20px;

		&:hover {
			cursor: pointer;
			color: var(--main-dark);
			transition: 0.5s ease-out;
		}
	}
}
.active {
	border-bottom: 2px solid var(--sec-dark);
	border-radius: 0px;
}
/*--------------------------- MENU BURGER ICON-------------------------*/
.menu-btn {
	position: absolute;
	top: 2.2%;
	right: 4.5%;
	z-index: 50;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	cursor: pointer;
	transition: all 0.5s ease-in-out;
}
.menu-btn__burger {
	width: 40px;
	height: 5px;
	background: var(--main-dark);
	border-radius: 5px;
	box-shadow: 0 2px 5px rgba(36, 51, 97, 0.42);
	transition: all 0.2s ease-in-out;
}

.menu-btn__burger::before,
.menu-btn__burger::after {
	content: "";
	position: absolute;
	width: 40px;
	height: 5px;
	background: var(--main-dark);
	border-radius: 5px;
	box-shadow: 0 2px 5px rgba(36, 51, 97, 0.42);
	transition: all 0.5s ease-in-out;
}
.menu-btn__burger::before {
	transform: translateY(-16px);
}
.menu-btn__burger::after {
	transform: translateY(16px);
}
/* ANIMATION */
.menu-btn.open .menu-btn__burger {
	transform: translateX(-40px);
	background: transparent;
	box-shadow: none;
}
.menu-btn.open .menu-btn__burger::before {
	transform: rotate(45deg) translate(35px, -35px);
}
.menu-btn.open .menu-btn__burger::after {
	transform: rotate(-45deg) translate(35px, 35px);
}

.menu-btn:hover .menu-btn__burger:before {
	background: transparent;
	transition: all 0.2s ease-in-out;
}
.menu-btn:hover .menu-btn__burger:after {
	background: transparent;
	transition: all 0.2s ease-in-out;
}
.menu-btn:hover .menu-btn__burger {
	background: transparent;
	transition: all 0.2s ease-in-out;
}
